<template>
<div class="b2c-page-bg">  
	<div>
		<div class="fs-sp">
			<div class="w-100 b2c-bg">
				<div class="w-100 b2c-bg-top">
					<div class="b2c-top-left"></div>
					<div class="b2c-top-center">VNTrip</div>
					<div class="b2c-top-right"></div>
				</div>
			</div>
			<div class="w-100"></div>
		</div>
		<div class=" fs-pc">
			<div class="b2c-pc-header">
				<h2>VNTrip</h2>
				<div class="nav"></div>
			</div>
		</div>
	</div>
	<b-modal body-class="p-2" hide-header hide-footer id="modal-vietlott" title="Thông báo!">
		<div class="row">
			<div class="col-12">
				<h5 class="modal-title">
					<img src="@/assets/images/logo/logo.svg" style="width:30%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
					<img src="@/assets/images/shop/partner/vntrip_web.png" style="width:40%" class="ml-2 mb-4" alt="icon-notify">
				</h5>
				<p class="text vietlott-info">Bạn sẽ được chuyển đến màn hình đặt phòng, vé máy bay của Công ty TNHH Công nghệ VNTRIP. Chính sách giá sẽ áp dụng theo chính sách hiện hành của Công ty VNTRIP. Trân trọng!</p>
			</div>
			<div class="col-12 text-center pt-2 mt-1 mb-3">
				<button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-vietlott');$router.push({ path: '/itshop/du-lich' });">Đóng</button>
				<a class="fs-but1" href="https://webview.vntrip.vn/">Tiếp tục</a>
			</div>
		</div>
	</b-modal>
</div>
</template>   
<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {
BFormInput
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
let images = {};
r.keys().map((item, index) => {
	images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
});
return images;
}
const images = importAll(
require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
components: {
	Swiper,
	SwiperSlide,
	BFormInput
},
data() {
	return {
	iframeSrc: '',
	phone:'',
	otp:''
	};
},
watch: {
	$route(to, from) {
	console.log('a');
	this.$bvModal.show('modal-vietlott');
	},
},
created() {

},
setup() {
	const onSwiper = (swiper) => {
	console.log(swiper);
	};
	const onSlideChange = () => {
	console.log("slide change");
	};
	return {
	onSwiper,
	onSlideChange,
	};
},
mounted() {
	this.$bvModal.show('modal-vietlott');
},
methods: {
	Img(pic) {
		return images[pic];
	},
	getVietlottView() {
		if (!/^([0-9]{10})$/.test(this.phone)) {
			this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
			icon: true,
			closeButton: "button",
			});
			return;
		}
		if (!/^([0-9]{6})$/.test(this.otp)) {
			this.$toast.error("Mã OTP gồm 6 chũ số", {
			icon: true,
			closeButton: "button",
			});
			return;
		}
		useJwt.get("vietlot/getlink", {params:{phone:this.phone,tran_id:'VL-'+Date.now(),otp:this.otp}})
		.then((response) => {
			console.log(response.data.result)
			this.$bvModal.hide('modal-vietlott');
			
			// window.open(response.data.result);
			window.location.assign(response.data.result)
		}).catch((err) => {
			this.loading(false);
			this.$toast.error("Vui lòng kiểm tra số điện thoại và mã OTP", {
				icon: true,
				closeButton: "button",
			});
		});;
	},
	async sendOTP() {
		if (this.otp.length > 0) {
			this.otp = "";
		}
		if (!/^([0-9]{10})$/.test(this.phone)) {
			this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
			icon: true,
			closeButton: "button",
			});
			return;
		}
		this.loading(true);
		useJwt.get("otp2/" + this.phone).then((response) => {
			this.$toast.info("Vui lòng kiểm tra tin nhắn để lấy mã OTP", {
				icon: true,
				closeButton: "button",
			});
			this.isDisableOTPText = false;
			this.loading(false);
		}).catch((err) => {
			this.loading(false);
			this.$toast.error(err.response.data.message, {
				icon: true,
				closeButton: "button",
			});
		});
	},
},
};
</script>
<style>
.b2c-page-bg {
background: rgba(242, 242, 242, 1);
}
.b2c-bg {
background: rgba(237, 31, 36, 1);
padding-bottom: 32px;
}
.b2c-bg-top {
background: rgba(237, 31, 36, 1);
display: flex;
margin-bottom: 0px;
}
.b2c-top-left::before {
content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
height: 44px;
padding: 8px 14px;
width: 20%;
}
.b2c-pc-header {
max-width: 122.4rem;
margin-left: auto;
margin-right: auto;
height: 70px;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);
width: 100%;
min-height: 500px;
}
.b2c-pc-header {
display: flex;
}
.b2c-pc-header h2 {
font-size: 24px;
font-weight: 700;
line-height: 69px;
color: #424242;
width: 50%;
}
.b2c-pc-header .nav{
background-image:none !important
}
.b2c-top-center {
width: 80%;
height: 22px;
font-family: "Roboto";
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 22px;
text-align: center;
color: #ffffff;
padding-top: 7px;
}
.b2c-top-right {
width: 20%;
}
.modal-title{
padding-top: 35px;
font-size: 30px !important;
color: '#373737' !important;
font-weight: 700;
text-align: center;
}
.vietlott-info{
line-height: 1.5;
padding: 10px 30px
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}
</style>